@font-face {
    font-family: 'Ovo';
    src: url(fonts/Ovo/Ovo-Regular.ttf);
}
@font-face {
    font-family: 'Mulish';
    src: url(fonts/Mulish/static/Mulish-Regular.ttf);
}
@media (min-width: 400px) {
    .sm{
        display: none;
    }
    .hambugger{
        display: flex;
    }
    .mobile-menu-flex{
        display: flex;
        flex-direction: column;
        /* justify-content: center;
        align-content: center;  */
        /* align-items: space-between; */
        margin: 20px; 
        margin-bottom: 60px;
    }
    .mobile-menu-none{
        display: none;
    }
}
@media (min-width: 550px) {
    .md{
        display: flex;
        flex-basis: 300px;
        justify-content: space-around;
        align-items: center;
    }
    .hambugger{
        display: none;
    }
    .mobile-menu-flex{
        display: none;
    }
}
@media (min-width: 600px) {
    .lg{
        display: flex;
        justify-content: space-between;
        align-content: center; 
        align-items: center;
        margin: 20px; 
        margin-bottom: 60px;
        background-color:darkred;
        color: indianred;
    }
}
.monini{color: rgb(68, 67, 67);}